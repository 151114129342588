<template>
  <div :key="refreshKey">
    <div class="my-3 pa-3 d-flex">
      <memberAvatar :avatar="avatar" />
      <div class="ml-3">
        <h3 class="text-lg word-break--break-all">{{ tokenName }}</h3>
        <h4 class="text-sm word-break--break-all">{{ email }}</h4>
      </div>
    </div>
    <v-list dense v-if="true" nav>
      <!-- <v-list-item dense @click="reload">
        <v-list-item-content>
          <v-list-item-title>Reload(清liff cache用)</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item v-if="isLogin" dense @click="$helper.account.logout()">
        <v-list-item-content>
          <v-list-item-title>登出</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-else dense @click="lineLogin">
        <v-list-item-content>
          <v-list-item-title>登入</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item :to="{ name: 'demo' }">
        <v-list-item-content>
          <v-list-item-title>demo</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <component v-for="(node, index) in getMenuData()" :is="getComponent(node)" :key="index" :node="node">
      </component>
    </v-list>
    <div class="pa-3 terms-wrap" v-if="$route.path.match(/provider-store/)">
      <router-link :to="{ name: 'service-terms' }">
        <span>服務條款</span>
      </router-link>
      和
      <router-link :to="{ name: 'privacy-terms' }">
        <span>隱私權條款</span>
      </router-link>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import {mapGetters} from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
    }
  },
  methods: {
    reload() {
      window.location.reload()
    },
    getMenuData() {
      const menu = this.getMenuByRole() || []
      return this.$helper.getComputedLinkList(menu)
    },
    checkMenu(arr) {
      const vm = this
      const currMemberRole = vm.currMemberRole
      const checkedMenu = []
      let curr
      let group
      for (const i in arr) {
        curr = arr[i]
        let checked = false

        if (Object.prototype.hasOwnProperty.call(curr, 'group')) {

          curr.group = curr.group.filter(item => item.allowedRoles.length != 0 && item.allowedRoles.some(name => currMemberRole.roles.includes(name)))
          if (curr.group.length != 0) {
            checked = true
          }
        } else if (Object.prototype.hasOwnProperty.call(curr, 'allowedRoles') && (curr.allowedRoles.length != 0 && curr.allowedRoles.some(name => currMemberRole.roles.includes(name)))) {
          checked = true
        }

        checked && checkedMenu.push(curr)
      }
      return checkedMenu
    },
    getMenuByRole() {
      const tokenRole = this.tokenRole
      
      if(tokenRole == this.$tokenRoleConstant.ROLE_PROVIDER && this.providerStatus != this.$providerApplyStatus.STATUS_APPLY) {
        return window.eagleLodash.cloneDeep(window.bootstrap.getMenu('unauthProvider'))
      }
      
      if (tokenRole != 'store' || (tokenRole == 'store' && this.hasStore)) {
        const menu = window.eagleLodash.cloneDeep(window.bootstrap.getMenu(tokenRole))
        
        // 非 主要管理者/共同管理員 要過濾選單
        const currMemberRole = this.currMemberRole
        if (currMemberRole && !currMemberRole._isManager) {
          return this.checkMenu(menu)
        }

        return menu
      }
      
      // 都沒有tokenRole, 代表沒註冊過, 用路由判斷menu
      if(this.$route.path.match(/provider-store/)) {
        return window.eagleLodash.cloneDeep(window.bootstrap.getMenu('unauthStore'))
      }
      return window.eagleLodash.cloneDeep(window.bootstrap.getMenu('unauthProvider'))
    },
    getComponent(node) {
      if(Array.isArray(node.group)) return 'menuGroup'
      return 'menuLink'
    },
    lineLogin() {
      if (this.$helper.whereLayout() == "provider-store") {
        this.$root.gaLogEvent('商店_點擊_登入')
        if(!this.layoutProvider.line_login_channel_id) {
          console.warn(`供應商channel id未設定`, this.layoutProvider.line_login_channel_id)
        }
        const host = `${window.location.origin}`
        const href = this.$router.resolve({
          name: 'store-line-login-return',
          params: {
            providerId: this.layoutProviderId,
          }
        }).href

        this.$oauth.login({
          provider: 'line',
          clientKey: this.layoutProvider.line_login_channel_id,
          successPath: this.$route.fullPath,
          keepLogin: true,
          isDebugMode: true,
          returnUri: `${host}${href}`,
        })
      } else {
        this.$root.gaLogEvent('供應商_點擊_登入')
        const url = this.$router.resolve({name: 'provider-switch-identity'}).href
        const host = `${window.location.origin}`
        const href = this.$router.resolve({
          name: 'line-login-return',
        }).href

        this.$oauth.login({
          provider: 'line',
          clientKey: this.oauthClient.line,
          successPath: url,
          keepLogin: true,
          isDebugMode: true,
          returnUri: `${host}${href}`,
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      isLogin: 'token/isLogin',
      tokenName: 'token/name',
      avatar: "token/avatar",
      email: "token/email",
      tokenRole: "token/tokenRole",
      oauthClient: "base/oauthClient",
      layoutProvider: 'base/layoutProvider',
      layoutProviderId: 'base/layoutProviderId',
      currMemberRole: 'member/currMemberRole'
    }),
    refreshKey() {
      return this.tokenRole
    },
    providerStatus() {
      return this.$store.getters[`base/layoutProviderStatus`]
    },
    storeStatus() {
      return this.$store.getters[`store/cooperationStatus`]
    },
    hasStore() {
      return this.$store.getters[`member/hasStore`]
    },
  },
  components: {
    menuGroup: () => import('./menu/group'),
    menuLink: () => import('./menu/link'),
    memberAvatar: () => import("@/modules/member/components/memberAvatar.vue"),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.terms-wrap
  position: absolute
  bottom: 20px
</style>
